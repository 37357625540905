import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import AuthenticationService from '@/services/AuthenticationService';

import Page401 from '@/components/Page401';
import Page404 from '@/components/Page404';
import Page500 from '@/components/Page500';
import PageSignInFailed from '@/components/PageSignInFailed';
import ExternalSignedOut from '@/components/SignedOut';
import Callback from '@/components/Callback';


const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { MessageKey: 'Home', requiresAuth: true},
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/callback',
    component: {
      render() {
        // Check if already logged in. This was introduced because when pressing F5 after successful login in Firefox,
        // the callback URL was loaded again but resulted in an error "No matching state found in storage".
        AuthenticationService.getUser().then(() => {
          // If not, then continue callback workflow
          if (!AuthenticationService.isAuthenticated)
            return AuthenticationService.callback();
        }).then(() => {
          // App needs to be restarted (instead of $router.replace) to allow custom theme which is only evaluated once on startup
          if (AuthenticationService.redirectUri)
            location.replace(AuthenticationService.redirectUri);
          else
            location.replace('/');
        }).catch((e) => {
          if (e === 'unauthorized') {
            // Logout user after 5 seconds
            setTimeout(() => { AuthenticationService.logout(); }, 5000);

            this.$router.push({ name: 'unauthorized' });
          }
          else
            this.$router.push({ name: 'signinfailed' });
        });

        return {Callback};
      }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: Page404
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: Page500
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'unauthorized',
    component: Page401
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'signinfailed',
    component: PageSignInFailed
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'signedout',
    component: ExternalSignedOut
  },
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Check for authentication before navigating to target
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!AuthenticationService.isAuthenticated) {
      AuthenticationService.login().then(() => {
        //console.log(AuthenticationService.isAuthenticated)
        if (AuthenticationService.isAuthenticated)
          next();
      }).catch((e) => {
        if (e === 'unauthorized') {
          // Logout user after 5 seconds
          setTimeout(() => { AuthenticationService.logout(); }, 5000);

          router.push({ name: 'unauthorized' });
        }
        else {
          router.push({ name: 'signinfailed' });
        }
      });
    }
    else {
      next();
    }
  } 
  else{
    next();
  }
});

export default router
